<template>
    <div class="col-12">
        <div class="row mt-4">
            <div
            v-for="(ano, index) in fastFeriados.anos"
            :key="index"
            class="col-sm-12 col-md-12 col-lg-3 text-center"
            >
            <div
                v-if="eventosAluno"
                :class="fastAnoSelecionado == ano.ano_base ? 'fast-cursor-pointer p-4 rounded shadow border text-info fast-ano-selecionado' : 'fast-cursor-pointer p-4 rounded shadow border text-info'"
                @click="selecionaAnoCalendario(ano.ano_base)"
            >
                <h1 class="mt-2">
                {{ ano.ano_base }}
                </h1>
                <h6 v-if="exibirFeriados">{{ ano.dias }} dias não letivos</h6>
            </div>
            <div
                v-else
                :class="fastAnoSelecionado == ano.ano_base ? 'fast-cursor-pointer p-4 rounded shadow border text-info fast-ano-selecionado' : 'fast-cursor-pointer p-4 rounded shadow border text-info'"
                @click="getfastFeriados($route.params.id_plataforma, ano.ano_base)"
            >
                <h1 class="mt-2">
                {{ ano.ano_base }}
                </h1>
                <h6 v-if="exibirFeriados">{{ ano.dias }} dias não letivos</h6>
            </div>
          </div>
        </div>
        <div v-if="fastCalendarios.length" class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-6">
            <span>Legenda:</span>&nbsp;
            <b-badge style="background-color: #ff8498">
                Hoje
            </b-badge> &nbsp;
            <b-badge v-if="exibirFeriados" style="background-color: #E10000">
                Dia não letivo
            </b-badge> &nbsp;
            <b-badge v-if="exibirFeriados" style="background-color: #fdba01">
                Dia conflitante
            </b-badge> &nbsp;            
            <b-badge v-if="programacaoTurma" style="background-color: #00e131">
                Aula programada
            </b-badge>
            <b-badge v-if="eventosAluno" style="background-color: #00e131">
                Registro de evento
            </b-badge>
            
            </div>
        </div>
        <div v-if="fastFeriados.loading" class="row mt-4">
          <div class="col-12 text-center p-4">
            <h4><b-icon
                      icon="gear-fill"
                      animation="spin"
                    /> Carregando calendário</h4>
          </div>
        </div>
        <div v-else-if="fastCalendarios.length" class="row mt-2">
            <div
            v-for="(i, index) in fastCalendarios"
            :key="index"
            class="col-sm-12 col-md-12 col-lg-4 mb-4"
            >
            <div class="row">
                <div class="col-12">
                <FunctionalCalendar
                    :new-current-date="i.calendarConfigs.newCurrentDate"
                    :configs="i.calendarConfigs"
                    @dayClicked="selecionaDiaCalendario"
                />                   
                </div>
            </div>
            <div
                v-if="i.calendarConfigs.markedDates.length && exibirDescricaoDatas"
                class="row"
            >
                <div class="col-12 mt-2">
                <strong>Datas marcadas do mês de {{ retornaNomeMes(i.calendarData.currentDate) }}</strong>
                </div>
                <div
                v-for="(d, index2) in i.calendarConfigs.markedDates"
                :key="index2"
                class="col-12"
                >
                 
                <small v-if="exibirFeriados && d.class == 'cal-feriado-marcado'" class="text-danger">- {{ retornaFeriadoDescricao(d.date) }}</small>   
                <small v-if="exibirFeriados && d.class == 'cal-feriado-conflito-marcado'" class="text-warning">- {{ retornaFeriadoDescricao(d.date) }}</small>   
                <small v-if="programacaoTurma && d.class == 'cal-turma-marcado'" class="text-success">- {{ retornaAulaDescricao(d.date, d) }}</small>             
                <small v-if="programacaoTurma && d.class == 'cal-turma-conflito-marcado'" class="text-warning">- {{ retornaAulaDescricao(d.date) }}</small>

                </div>
            </div>
            </div>  
        </div>
    </div>
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "FastSecretariaCalendario",
    props: {
        anoBase: {
            type: Number,
            required: false,
        },
        retornaDataSelecionada: {
          type: Function,
          required: true
        },
        exibirFeriados: {
          type: Boolean,
          required: false,
          default: true
        },
        exibirDescricaoDatas: {
          type: Boolean,
          required: false,
          default: true
        },
        programacaoTurma: {
          type: Array,
          required: false
        },
        eventosAluno: {
          type: Array,
          required: false
        },
        idUsuarioAluno: {
          type: Number,
          required: false
        },
        retornaAnoSelecionado: {
          type: Function,
          required: true
        },
    },
    
    mixins: [methods],
    data: function () {
        return {
            fastCalendarios: [],
            fastFeriados: {
                total: [],
                filtro: [],
                paginacao: [],
                anos: [],
                loading: true
            },
            fastAnoAtual: new Date().getFullYear(),
            fastAnoSelecionado: ""
        }        
    },
    mounted: function () {       
      this.getfastFeriadosAnos(this.$route.params.id_plataforma)

      if (this.anoBase) {
        this.fastAnoSelecionado = this.anoBase
        this.getfastFeriados(this.$route.params.id_plataforma, this.anoBase)
      }
      
    },
    methods: {
        getDaysInMonth(m, y) {
        return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
        },
        retornaNomeMes(data){
          const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
          return meses[data.getMonth()]
        },
        retornaFeriadoDescricao(data){      
          let descricao = "Sem descrição"
            this.fastFeriados.total.forEach(f => {
              if (data == `${f.dia}/${f.mes}/${f.ano_base}`) descricao = f.nome_feriado
            })

            let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
            let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
            let ano = data.split("/").pop()

            return `${dia}/${mes}/${ano} - ${descricao}`
        },
        retornaAulaDescricao(data, d){
          let descricao = ""

            this.programacaoTurma.forEach(f => {            
              if (f.data_programado) {
                if (f.data_programado.split("T").length) {
                  let dia_p = parseInt(f.data_programado.split("T")[0].split("-")[2])
                  let mes_p = parseInt(f.data_programado.split("T")[0].split("-")[1])
                  let ano_p = parseInt(f.data_programado.split("T")[0].split("-")[0])
                  if (data == `${dia_p}/${mes_p}/${ano_p}`) {
                    if (descricao) descricao += `, ${f.sequencia} - ${f.titulo_aula}`
                    else
                      descricao = `${f.sequencia} - ${f.titulo_aula}`
                  }
                }                    
              }                              
            })

            let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
            let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
            let ano = data.split("/").pop()

            return `${dia}/${mes}/${ano} - ${descricao}`
        },
        async getfastFeriados(id_plataforma, ano_base){
          console.log("getfastFeriados")
          this.fastAnoSelecionado = ano_base
          this.fastCalendarios = []

          this.fastFeriados.loading = true  
            
          this.promiseGetFastApi("api/fast_secretaria_feriado/lista", "id_plataforma=" + id_plataforma + "&ano_base=" + ano_base).then(obj => {
            if (obj.length) {
              this.fastFeriados.total = obj
              this.fastFeriados.filtro = obj
            }
            else {
              this.fastFeriados.total = []
              this.fastFeriados.filtro = []
            }        
            
            // Percorre os 12 meses
            for (let i = 1; i <= 12; i++) {
              // Array de feriados
              let markedDates = []  

              
              
              if (this.exibirFeriados) {
                // Varre feriados do ano e verifica se pertence ao mês
                this.fastFeriados.total.forEach(f => {                              
                  if (f.mes == i) markedDates.push({ date: `${f.dia}/${f.mes}/${f.ano_base}`, class: "cal-feriado-marcado", dia: parseInt(f.dia) });            
                })
              } 
              
              if (this.programacaoTurma) {
                // Varre aulas da turma e verifica se pertence ao mês
                this.programacaoTurma.forEach(f => {            
                  if (f.data_programado) {
                    if (f.data_programado.split("T").length) {
                      let dia = parseInt(f.data_programado.split("T")[0].split("-")[2])
                      let mes = parseInt(f.data_programado.split("T")[0].split("-")[1])
                      let ano = parseInt(f.data_programado.split("T")[0].split("-")[0])
                      
                      if (mes == i && ano == ano_base) {
                        let aulasNoMesmoDia = false
                        let classe = "cal-turma-marcado"
                        markedDates.forEach(d => {
                          // Aulas no mesmo dia
                          if (d.date == `${dia}/${mes}/${ano}` && d.class == 'cal-turma-marcado') {
                            aulasNoMesmoDia = true
                          }
                          // Aula no feriado marca as duas datas conflitantes
                          else if (d.date == `${dia}/${mes}/${ano}` && d.class == 'cal-feriado-marcado') {
                            d.class = "cal-feriado-conflito-marcado"
                            classe = "cal-turma-conflito-marcado"
                          }
                        })

                        if (!aulasNoMesmoDia) markedDates.push({ date: `${dia}/${mes}/${ano}`, class: classe, dia: dia });
                      }
                    }                    
                  }                              
                })
              }

              // Histórico de eventos do aluno
              if (this.eventosAluno) {        
                this.eventosAluno.forEach(f => {            
                  if (f.data_evento) {
                    if (f.data_evento.split("T").length) {
                      let dia = parseInt(f.data_evento.split("T")[0].split("-")[2])
                      let mes = parseInt(f.data_evento.split("T")[0].split("-")[1])
                      let ano = parseInt(f.data_evento.split("T")[0].split("-")[0])
                      
                      if (mes == i && ano == ano_base) {                        
                        let aulasNoMesmoDia = false
                        let classe = "cal-turma-marcado"
                        markedDates.forEach(d => {
                          // Aulas no mesmo dia
                          if (d.date == `${dia}/${mes}/${ano}` && d.class == 'cal-turma-marcado') {
                            aulasNoMesmoDia = true
                          }
                        })

                        if (!aulasNoMesmoDia) markedDates.push({ date: `${dia}/${mes}/${ano}`, class: classe, dia: dia });
                      }

                    }                    
                  }                              
                })                
              }

             
              // Reeordena (não está reeordando os que tem 0 na frente)   
              markedDates.sort((a,b) => (a.dia > b.dia) ? 1 : ((b.dia > a.dia) ? -1 : 0))
              // Cria objeto com parâmetros do calendário
              let obj = {
                  calendarData: {
                    currentDate: new Date(`${ano_base}-${i}-1`),
                    dateRange: {
                      end: `${this.getDaysInMonth(i, ano_base)}/${i}/${ano_base}`,
                      start: `1/${i}/${ano_base}`
                    },
                    multipleDateRange: [],
                    selectedDate: false,
                    selectedDateTime: false,
                    selectedDates: [],
                    selectedDatesItem: "",
                    selectedHour: "00",
                    selectedMinute: "00",
                  },
                  calendarConfigs: {                
                    sundayStart: true,
                    dateFormat: "dd/mm/yyyy",
                    isDatePicker: false,
                    isDateRange: false,
                    limits: {min: `1/${i}/${ano_base}`, max: `${this.getDaysInMonth(i, ano_base)}/${i}/${ano_base}`},   
                    changeMonthFunction: false,
                    changeYearFunction: false,                       
                    newCurrentDate: new Date(`${ano_base}-${i}-1`),
                    markedDates: markedDates,
                  } ,
                  ref: `fastCalendario_${i}_${ano_base}`
              }    
              // Insere objeto no array que será exibido pelo v-for no html
              this.fastCalendarios.push(obj)          
            }

            this.fastFeriados.loading = false
          }).catch(e => { 
            this.exibeToasty("Erro ao buscar feriados", "error");
            this.fastFeriados.loading = false
          })
        },
        async getfastFeriadosAnos(id_plataforma){  
          this.promiseGetFastApi("api/fast_secretaria_feriado/lista_anos", "id_plataforma=" + id_plataforma).then(res => {
            this.fastFeriados.anos = []
            let anoAtualIncluido = false
            let anoFuturoIncluido = false
            if (res.length) {
              res.forEach(a => {
                if (a.ano_base == this.fastAnoAtual) anoAtualIncluido = true            
                if (a.ano_base == parseInt(this.fastAnoAtual) + 1) anoFuturoIncluido = true          
              })
              if (this.eventosAluno) {
                this.fastFeriados.anos = res.filter(a => {
                 return ((a.ano_base == this.fastAnoAtual) || (a.ano_base == parseInt(this.fastAnoAtual) - 1))
                })
              } else {
                this.fastFeriados.anos = res
              }
              
            }     
            
            if (!anoAtualIncluido) this.fastFeriados.anos.push({ano_base: this.fastAnoAtual, dias: 0})
            if (!anoFuturoIncluido && !this.eventosAluno) this.fastFeriados.anos.push({ano_base: parseInt(this.fastAnoAtual) + 1, dias: 0})
            
          }).catch(e => { 
            this.exibeToasty("Erro ao buscar anos", "error");
          })
        },
        selecionaDiaCalendario(cal){
          this.retornaDataSelecionada(cal)
        },
        selecionaAnoCalendario(ano){
          console.log("Ano selecionado", ano)
          this.retornaAnoSelecionado(ano)
        }
    }
}
</script>
<style scoped>
.cal-feriado-marcado {
  background-color: #E10000 !important;
  color: #fff;
}
.cal-turma-marcado {
  background-color: #00e131 !important;
  color: #fff;
}
.cal-turma-feriado-marcado {
  background-color: #fdba01 !important;
  color: #fff;
}
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-ano-selecionado {
  background-color: #eee;
}
.vfc-disabled {
  opacity: 0;
}
</style>